import { ref, watch, computed } from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'
import router from '@/router/index'

// Notification

export default function useLinkedDeliveryReceiptsList() {
  // Use toast
  const toast = useToast()
  const route = router.history.current

  const refLinkedDeliveryReceiptsTable = ref(null)

  // Table Handlers
  const tableColumns = [
    {
      key: 'stringID',
      label: 'Receipt id',
      sortable: true,
    },
    {
      key: 'createdAt',
      label: 'CREATED',
      sortable: true,
    },
    {
      key: 'receivedDate',
      label: 'Date Received',
      sortable: true,
    },
    {
      key: 'remarks',
      label: 'Remarks',
      sortable: true,
    },
    {
      key: 'purchaseRequest',
      label: 'vendor & PO/PRF',
      sortable: true,
    },
    {
      key: 'attachments',
      label: 'Files uploaded',
      sortable: true,
    },
    {
      key: 'createdBy',
      label: 'REceived by',
      sortable: true,
    },
    { key: 'status', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(route.query.perPage ?? process.env.VUE_APP_PER_PAGE_CONTENT)
  const totalDeliveryReceipts = ref(0)
  const currentPage = ref(route.query.page ?? 1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref(route.query.search ?? '')
  const sortBy = ref(route.query.sortBy ?? 'stringID')
  const isSortDirDesc = ref(route.query.sortDesc === 'true')
  const statusFilter = ref([])
  if (Array.isArray(route.query.status)) {
    statusFilter.value = route.query.status
  } else if (route.query.status) {
    statusFilter.value = [route.query.status]
  }
  const myRequest = ref(false)
  if (route.name === 'purchasing-my-delivery-receipts-index') {
    myRequest.value = true
  }
  const toDeletedID = ref(null)
  const type = ref('linked')
  const deliveryReceiptData = ref([])
  const createdAtFilter = ref(route.query.createdAt || '')
  const receivedAtFilter = ref(route.query.receivedAt || '')
  let initialLoad = true

  const dataMeta = computed(() => {
    const localItemsCount = refLinkedDeliveryReceiptsTable.value ? refLinkedDeliveryReceiptsTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDeliveryReceipts.value,
    }
  })

  const refetchData = () => {
    refLinkedDeliveryReceiptsTable.value.refresh()
  }

  watch([searchQuery, statusFilter, createdAtFilter, receivedAtFilter], () => {
    currentPage.value = 1
    refetchData()
  }, { deep: true })

  watch([currentPage], () => {
    refetchData()
  }, { deep: true })

  const fetchDeliveryReceipts = (ctx, callback) => {
    store
      .dispatch('app-linked-delivery-receipt/fetchDeliveryReceipts', {
        search: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        status: statusFilter.value,
        createdAt: createdAtFilter.value,
        receivedAt: receivedAtFilter.value,
        type: type.value,
        myRequest: myRequest.value,
      },
      { root: true })
      .then(response => {
        const { deliveryReceipts, total } = response.data
        deliveryReceiptData.value = deliveryReceipts
        callback(deliveryReceipts)
        totalDeliveryReceipts.value = total
        if (!initialLoad) {
          router.replace({
            query: {
              search: searchQuery.value,
              perPage: perPage.value,
              page: currentPage.value,
              sortBy: sortBy.value,
              sortDesc: isSortDirDesc.value,
              status: statusFilter.value,
              createdAt: createdAtFilter.value,
              receivedAt: receivedAtFilter.value,
            },
          }).catch(() => {})
        }
        initialLoad = false
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteDeliveryReceipt = () => {
    store
      .dispatch('app-linked-delivery-receipt/deleteDeliveryReceipt', {
        id: toDeletedID.value,
      },
      { root: true })
      .then(response => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message ?? '',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
      .catch(error => {
        toast({
          component: ToastificationContent,
          props: {
            title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveDeliveryReceiptStatusVariant = status => {
    if (status === 'linked') return 'success'
    if (status === 'pending') return 'warning'
    return 'primary'
  }

  return {
    fetchDeliveryReceipts,
    tableColumns,
    perPage,
    currentPage,
    totalDeliveryReceipts,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refLinkedDeliveryReceiptsTable,

    resolveDeliveryReceiptStatusVariant,
    refetchData,

    toDeletedID,
    deleteDeliveryReceipt,
    deliveryReceiptData,

    // Extra Filters
    statusFilter,
    createdAtFilter,
    receivedAtFilter,
  }
}
